import React from 'react';
import { Link } from '@reach/router';
import menuCss from './menu.module.css';

const type = {
  info: { icon: 'fa fa-info', path: '/info', text: 'Info' },
  login: { icon: 'fa fa-sign-in', path: '/login', text: 'Login' },
  logout: { icon: 'fa fa-sign-out', path: '/logout', text: 'Logout' },
  feedback: { icon: 'fa fa-pencil-square-o', path: '', text: 'Feedback' },
};

export default (props) => {
  return (
    <Link className={menuCss.footerbtn} to={props.type.path} title={props.type.text} onClick={props.onClick}>
      <i className={props.type.icon} alt={props.type.text} />
      <span>{props.type.text}</span>
    </Link>
  );
};

export { type };
