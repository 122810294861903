import { getCollection, getData, firestoreInit, get } from './firestore';
import faja from './faja.json';
// import { invalid } from 'moment';

let loaded = false;
// let defaultCategories = null;
let searchCategories = null;
let totalFilms = -1;
const randomOrderBy = () => {
  const orders = [
    { by: 'views', dir: 'desc' },
    { by: 'title', dir: 'desc' },
    { by: 'title', dir: 'asc' },
    { by: 'dateAdded', dir: 'desc' },
    { by: 'publishedAt', dir: 'desc' },
    { by: 'duration', dir: 'desc' },
    { by: 'duration', dir: 'asc' },
  ];
  const index = Math.floor(Math.random() * orders.length);
  return orders[index];
};

const init = async () => {
  if (!loaded) await firestoreInit();
  loaded = true;
};

const getTopViewed = (limit) => {
  const coll = getCollection('films');
  return getData(coll.orderBy('views', 'desc').limit(limit));
};

const getMostRecent = (limit, offset = Date.now()) => {
  const coll = getCollection('films');
  return getData(coll.orderBy('dateAdded', 'desc').startAfter(offset).limit(limit));
};

const getByMeta = (meta, limit) => {
  const order = randomOrderBy();
  const coll = getCollection('films');
  return getData(
    coll.where('searchFields', 'array-contains', meta.toLowerCase()).orderBy(order.by, order.dir).limit(limit)
  );
};

const getByDuration = (condition, duration, limit) => {
  const coll = getCollection('films');
  return getData(coll.where('duration', condition, duration).orderBy('duration').limit(limit));
};

const search = async (text, offset = 100000000000) => {
  const pageSize = 20;
  const coll = getCollection('films');
  try {
    const meta = await getData(
      coll.where('searchFields', 'array-contains', text).orderBy('views', 'desc').startAfter(offset).limit(pageSize)
    );
    return meta;
  } catch (e) {
    console.log(e);
  }
};

const getAllCategoriesElements = async () => {
  if (!searchCategories) searchCategories = await get('computed', 'categories');
  return searchCategories.all.split('*');
};

const getTopCategory = (category) => {
  // if (!defaultCategories) {
  //   defaultCategories = await get('computed', 'topCategories');
  // }
  // return defaultCategories[category];
  return faja[category];
};

const getDefaultCategories = () => {
  return [
    { title: 'Most Viewed', func: () => getTopCategory('views'), static: true },
    // { title: 'Latest', func: () => getMostRecent(10) },
    { title: 'Featured', func: () => getTopCategory('featured'), static: true },
    { title: 'Over One Hour', func: () => getTopCategory('oneHour'), static: true },
  ];
};

const categories = [
  { title: 'Under Ten minutes', func: () => getByDuration('<', 600, 10) },
  { title: 'Under Five minutes', func: () => getByDuration('<', 300, 10) },
  { title: 'Long Videos', func: () => getByDuration('>', 2000, 10) },
  // { title: '9a/9a+ Climbing', func: () => getByMeta('9a', 10) },
  // { title: '9b/9b+ Climbing', func: () => getByMeta('9b', 10) },

  { title: 'Bouldering', func: () => getByMeta('bouldering', 10) },
  { title: 'Sport', func: () => getByMeta('sport', 10) },
  { title: 'Mellow', func: () => getByMeta('mellow', 10) },
  { title: 'Adam Ondra', func: () => getByMeta('adam ondra', 10) },
  { title: 'Daniel Woods', func: () => getByMeta('daniel woods', 10) },
  { title: 'Hot Aches', func: () => getByMeta('hot aches', 10) },
  { title: 'Trad', func: () => getByMeta('trad', 10) },
  { title: 'America', func: () => getByMeta('america', 10) },
  { title: 'Chris Sharma', func: () => getByMeta('chris sharma', 10) },
  { title: 'Usa', func: () => getByMeta('usa', 10) },
  { title: 'Dave Macleod', func: () => getByMeta('dave macleod', 10) },
  { title: 'Switzerland', func: () => getByMeta('switzerland', 10) },
  { title: 'Spain', func: () => getByMeta('spain', 10) },
  { title: 'Jimmy Web', func: () => getByMeta('jimmy web', 10) },
  { title: 'Neil Gresham', func: () => getByMeta('neil gresham', 10) },
  { title: 'Petzl', func: () => getByMeta('petzl', 10) },
  { title: 'Masterclass', func: () => getByMeta('masterclass', 10) },
  { title: 'Colorado', func: () => getByMeta('colorado', 10) },
  { title: 'Dave Graham', func: () => getByMeta('dave graham', 10) },
  { title: 'France', func: () => getByMeta('france', 10) },
  { title: 'Yosemite', func: () => getByMeta('yosemite', 10) },
  { title: 'Roof', func: () => getByMeta('roof', 10) },
  { title: 'Technique', func: () => getByMeta('technique', 10) },
  { title: 'Black Diamond', func: () => getByMeta('black diamond', 10) },
  { title: 'Japan', func: () => getByMeta('japan', 10) },
  { title: 'Giuliano Cameroni', func: () => getByMeta('giuliano cameroni', 10) },
  { title: 'Crack', func: () => getByMeta('crack', 10) },
  { title: 'Frictionlabs', func: () => getByMeta('frictionlabs', 10) },
  { title: 'Mad Rock', func: () => getByMeta('mad rock', 10) },
  { title: 'Yuji Hirayama', func: () => getByMeta('yuji hirayama', 10) },
  { title: 'Five Ten', func: () => getByMeta('five ten', 10) },
  { title: 'Chile', func: () => getByMeta('chile', 10) },
  { title: 'La Sportiva', func: () => getByMeta('la sportiva', 10) },
  { title: 'Rocklands', func: () => getByMeta('rocklands', 10) },
  { title: 'Crimp', func: () => getByMeta('crimp', 10) },
  { title: 'Arete', func: () => getByMeta('arete', 10) },
  { title: 'Ryuichi Murai', func: () => getByMeta('ryuichi murai', 10) },
  { title: `Arc'teryx`, func: () => getByMeta(`arc'teryx`, 10) },
  { title: 'The North Face', func: () => getByMeta('the north face', 10) },
  { title: 'Nalle Hukkataival', func: () => getByMeta('nalle hukkataival', 10) },
  { title: 'Shawn Raboutou', func: () => getByMeta('shawn raboutou', 10) },
  { title: 'Overhang', func: () => getByMeta('overhang', 10) },
  { title: 'Paul Robinson', func: () => getByMeta('paul robinson', 10) },
  { title: 'Pete Whittaker', func: () => getByMeta('pete whittaker', 10) },
  { title: 'Margalef', func: () => getByMeta('margalef', 10) },
  { title: 'Fontainebleau', func: () => getByMeta('fontainebleau', 10) },
  { title: 'Patagonia', func: () => getByMeta('patagonia', 10) },
  { title: 'Roctrip', func: () => getByMeta('roctrip', 10) },
  { title: 'Alex Megos', func: () => getByMeta('alex megos', 10) },
  { title: 'Fred Nicole', func: () => getByMeta('fred nicole', 10) },
  { title: 'Patrick Edlinger', func: () => getByMeta('patrick edlinger', 10) },
  { title: 'Nina Caprez', func: () => getByMeta('nina caprez', 10) },
  { title: 'Keenan Takahashi', func: () => getByMeta('keenan takahashi', 10) },
  { title: 'Prana', func: () => getByMeta('prana', 10) },
  { title: 'Arco', func: () => getByMeta('arco', 10) },
  { title: 'Patrick Berhault', func: () => getByMeta('patrick berhault', 10) },
  { title: 'James Pearson', func: () => getByMeta('james pearson', 10) },
  { title: 'Ben Moon', func: () => getByMeta('ben moon', 10) },
  { title: 'Oliana', func: () => getByMeta('oliana', 10) },
  { title: 'Bishop', func: () => getByMeta('bishop', 10) },
  { title: 'Hazel Findlay', func: () => getByMeta('hazel findlay', 10) },
  { title: 'Alex Honnold', func: () => getByMeta('alex honnold', 10) },
  { title: 'Reel Rock', func: () => getByMeta('reel rock', 10) },
  { title: 'Cresciano', func: () => getByMeta('cresciano', 10) },
  { title: 'Utah', func: () => getByMeta('utah', 10) },
  { title: 'Squamish', func: () => getByMeta('squamish', 10) },
  { title: 'Norway', func: () => getByMeta('norway', 10) },
  { title: 'Siurana', func: () => getByMeta('siurana', 10) },
  { title: 'Italy', func: () => getByMeta('italy', 10) },
  { title: 'Smith Rock', func: () => getByMeta('smith rock', 10) },
];

const getRandomCategory = (currentItems) => {
  const availableCategories = categories.filter((cat) => !currentItems.find((item) => cat.title === item.props.title));
  return availableCategories[Math.floor(Math.random() * availableCategories.length)];
};

const getRandomFilm = async () => {
  let film = [];
  const totalFilms = await getTotalFilms();
  const pos = Math.floor(Math.random() * totalFilms);
  const coll = getCollection('films');
  film = await getData(coll.where('pos', '==', pos).limit(1));
  return film[0];
};

const getStaticFilmById = (id) => {
  let films = [];
  Object.keys(faja).forEach((key) => {
    films = films.concat(faja[key]);
  });
  return films.find((film) => film.id === id);
};

const getFilmById = async (id) => {
  return get('films', id);
};

const getTotalFilms = async () => {
  if (totalFilms > -1) return totalFilms;
  const reportsFilms = await get('reports', 'films');
  totalFilms = reportsFilms.films || 500;
  return totalFilms;
};

export {
  init,
  getTopViewed,
  getMostRecent,
  getByMeta,
  getByDuration,
  search,
  getAllCategoriesElements,
  getRandomCategory,
  getRandomFilm,
  getDefaultCategories,
  getFilmById,
  getStaticFilmById,
  getTopCategory,
};
