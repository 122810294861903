import React, { useEffect, useState } from 'react';
import analytics from '../../data/analytics';
import headerWatchCss from './headerWatch.module.css';

const NextCounter = (props) => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      const newCounter = counter - 1;
      setCounter(newCounter);
      if (counter === 0) {
        analytics.logEvent(analytics.events.playNextCountedDown);
        props.onEnd();
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter, props]);

  return (
    <div className={headerWatchCss.counter}>
      <button
        className={[headerWatchCss.back, headerWatchCss.flash].join(' ')}
        onClick={props.onCancel}
        title="Cancel Autoplay"
      >
        <i className="fa fa-ban" alt="cancel" />
        <span>Playing next in {counter}...</span>
      </button>
    </div>
  );
};

export default NextCounter;
