import React, { useEffect } from 'react';
import Header from '../header/BasicHeader';
import fullpageCss from './fullpage.module.css';
import Copyright from '../about/Copyright';

const FullPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);
  const hideCopyright = props.copyright === false;
  return (
    <div className={props.center ? fullpageCss.center : fullpageCss.default}>
      <Header />
      <div style={{ height: '60px' }}></div>
      {props.children}
      {!hideCopyright ? <Copyright height="800px" /> : ''}
    </div>
  );
};

export default FullPage;
