import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';

export default function Feedback(props) {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const onSubmit = () => {
    setRating(0);
    props.onSubmit(rating, feedback);
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          <DialogContentText>Leave any kind of feedback! Thanks.</DialogContentText>
          <Typography component="legend">Rating</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
          <TextField
            autoFocus
            multiline={true}
            rows={4}
            margin="dense"
            id="name"
            label="Feedback"
            type="text"
            fullWidth
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary" type="submit">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
