import React from 'react';
import YouTube from 'react-youtube';

/*
<YouTube
  videoId={string}                  // defaults -> null
  id={string}                       // defaults -> null
  className={string}                // defaults -> null
  containerClassName={string}       // defaults -> ''
  opts={obj}                        // defaults -> {}
  onReady={func}                    // defaults -> noop
  onPlay={func}                     // defaults -> noop
  onPause={func}                    // defaults -> noop
  onEnd={func}                      // defaults -> noop
  onError={func}                    // defaults -> noop
  onStateChange={func}              // defaults -> noop
  onPlaybackRateChange={func}       // defaults -> noop
  onPlaybackQualityChange={func}    // defaults -> noop
/>
*/

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    modestbranding: 1,
    rel: 0,
    iv_load_policy: 3,
  },
};

const Player = (props) => (
  <YouTube
    videoId={props.videoId} // defaults -> null
    id={props.videoId} // defaults -> null
    opts={opts}
    onEnd={props.onEnd} // defaults -> {}
  />
);

export default Player;
