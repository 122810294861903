import React, { useEffect } from 'react';
import './Main.css';
import 'swiper/css/swiper.css';
import Header from './header/Header';
import { sendAlert } from '../utils';

import Copyright from './about/Copyright';

function Main(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  useEffect(() => {
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) sendAlert('ie11', 'Unfortunately IE11 is not supported. Please use an up to date browser.', 'warning');
  });

  return (
    <div>
      <Header pathname={props.location.pathname} />
      <main className="main">{props.children}</main>
      {/* <Footer pathname={props.location.pathname} /> */}
      <Copyright />
    </div>
  );
}

export default Main;
