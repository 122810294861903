import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Alert from './layout/common/Alert';
import Main from './layout/Main';
import Watch from './layout/watch/Watch';
import Home from './layout/Home';
import Search from './layout/search/Search';
import Info from './layout/about/Info';
import About from './layout/about/About';
import NotFound from './layout/common/404';
import Logout from './layout/Logout';
import Login from './layout/Login';
import Privacy from './layout/about/Privacy';
import Terms from './layout/about/Terms';
// import auth from './data/auth';

// const AuthenticatedRoute = (props) => {
//   const Component = props.component;
//   const content = !auth.getUser() ? <Redirect to="/login" noThrow={true} /> : <Component {...props} />;
//   return content;
// };

const theme = createMuiTheme({
  palette: { type: 'dark' },
  overrides: {
    MuiButton: {
      textPrimary: {
        color: 'white',
      },
    },
    MuiInput: {
      root: {
        '&$focused': {
          color: 'white',
        },
      },
      underline: {
        '&:after': {
          borderBottomColor: 'red',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'white',
        },
      },
    },
    MuiRating: {
      root: {
        color: 'red',
      },
    },
  },
});

const App = () => {
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.body.addEventListener('alert', openAlert);
    return () => {
      document.body.removeEventListener('alert', openAlert);
    };
  });

  const openAlert = (e) => {
    setMessage(e.detail);
    setAlert(true);
  };

  const closeAlert = () => {
    setAlert(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <AuthenticatedRoute component={Main} path="/"> */}
        <Main path="/">
          <Home path="/" />
          <Watch path="/watch/:movieId" />
          <Search path="/search/:query" />
          <NotFound default />
        </Main>
        {/* </AuthenticatedRoute> */}
        <Login path="/login" />
        <Logout path="/logout" />
        <Privacy path="/privacy" />
        <Terms path="/terms" />
        <Info path="/info" />
        <About path="/about" />
        <NotFound default />
        {/* <AuthenticatedRoute path="fu3nfknw" /> */}
      </Router>
      <Alert open={alert} message={message} onClose={closeAlert} />
    </ThemeProvider>
  );
};

export default App;
