import React from 'react';
import { Link } from '@reach/router';
import Menu from './Menu';
import header from './header.module.css';

function Header() {
  const titleElement = (
    <div className={header.header}>
      <div className={header.navigation}>
        <div className={header.leftNav}>
          <div className={header.logo}>
            <Link to="/" title="Home">
              <img src="/logo.png" alt="Home" />
              <span>couchclimbs</span>
            </Link>
          </div>
          <div></div>
        </div>
        <div className={header.rightNav}>
          <Menu isAnon={true} />
        </div>
      </div>
    </div>
  );

  return <header className={header.mheader}>{titleElement}</header>;
}

export default Header;
