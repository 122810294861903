import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ListFilms from './list/ListFilms';
import { getRandomCategory, getDefaultCategories } from '../data/films';
import analytics from '../data/analytics';

const Home = (props) => {
  const defaultItems = getDefaultCategories().map((category) => (
    <ListFilms
      key={category.title}
      title={category.title}
      onDetail={props.onDetail}
      getFilms={category.func}
      defaultCat={category.static}
    />
  ));
  const [items, setItems] = useState(defaultItems);
  const [endScroll, setEndScroll] = useState(true);

  const fetchData = () => {
    if (items.length > 10) setEndScroll(false);
    analytics.logEvent(analytics.events.loadCategory);
    const category = getRandomCategory(items);
    if (!category) return;
    setItems(
      items.concat(
        <ListFilms key={category.title} title={category.title} onDetail={props.onDetail} getFilms={category.func} />
      )
    );
  };

  document.title = 'Couchclimbs - A Library of Rock Climbing Movies';

  return (
    <div className="list">
      <InfiniteScroll
        dataLength={items.length}
        next={fetchData}
        hasMore={endScroll}
        loader={<div style={{ height: '200px' }}></div>}
        style={{ overflow: 'inherit' }}
        scrollThreshold="750px"
      >
        {items}
      </InfiniteScroll>
    </div>
  );
};

export default Home;
