import firebase from 'firebase/app';
import { set, get, add, getSubCollection, getParentsData } from './firestore';
import auth from './auth';
import moment from 'moment';

const addToMyList = async (film) => {
  const user = auth.getUser();
  const data = await get('users', user.uid);
  if (data && data.myList > 19) {
    throw new Error(
      'My List can only save up to 20 movies. Please make some space in the list before adding more movies.'
    );
  }
  await set(
    'films',
    `${film.id}/userData/${user.uid}`,
    { myList: true, id: user.uid, dateAdded: film.dateAdded || moment.now() },
    true
  );
  set('users', user.uid, { myList: firebase.firestore.FieldValue.increment(1) }, true);
};

const removeFromMyList = async (filmId) => {
  const user = auth.getUser();
  await set('films', `${filmId}/userData/${user.uid}`, { myList: false, id: user.uid }, true);
  set('users', user.uid, { myList: firebase.firestore.FieldValue.increment(-1) }, true);
};

const getMyList = async (filmId) => {
  const user = auth.getUser();
  return await get('films', `${filmId}/userData/${user.uid}`);
};

const getByMyList = async (limit = 20, offset = moment.now()) => {
  const user = auth.getUser();
  const coll = getSubCollection('userData');
  return getParentsData(
    coll
      .where('id', '==', `${user.uid}`)
      .where('myList', '==', true)
      .orderBy('dateAdded', 'desc')
      .startAfter(offset)
      .limit(limit)
  );
};

const sendFeedback = async (rating, feedback) => {
  const user = auth.getUser();
  const data = {
    rating,
    feedback,
    user: user.uid,
    anon: user.isAnonymous,
    timestamp: moment.now(),
  };
  try {
    await add('feedback', data);
  } catch (e) {
    console.log(e);
  }
};

export { addToMyList, removeFromMyList, getMyList, getByMyList, sendFeedback };
