import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import analytics from '../../data/analytics';
import headerWatchCss from './headerWatch.module.css';
import { playRandomFilm } from '../../utils';
import NextCounter from './NextCounter';

function Header(props) {
  const [playingNext, setNext] = useState(false);

  useEffect(() => {
    document.body.addEventListener('headerWatch', onAction);
    return () => {
      document.body.removeEventListener('headerWatch', onAction);
    };
  });

  const onAction = (e) => {
    const payload = e.detail;
    if (payload.action === 'next') setNext(true);
  };

  const onCancelCounter = () => {
    analytics.logEvent(analytics.events.cancelPlayNext);
    setNext(false);
  };

  const backToBrowse = () => {
    const backTo = localStorage.getItem('backto') || '/';
    const to = backTo.indexOf('watch') > -1 ? '/' : -1;
    analytics.logEvent(analytics.events.backToBrowsing);
    navigate(to);
  };

  const playNext = () => {
    analytics.logEvent(analytics.events.playNext);
    playRandomFilm();
    setNext(false);
  };

  const nextPlaying = <NextCounter onEnd={playNext} onCancel={onCancelCounter} />;
  const nextButton = (
    <button className={headerWatchCss.back} onClick={playNext}>
      <i className="fa fa-arrow-right" alt="next" />
      <span>next</span>
    </button>
  );

  return (
    <header className={headerWatchCss.mheader}>
      <div className={headerWatchCss.navigation}>
        <div className={headerWatchCss.leftNav}>
          <button className={headerWatchCss.back} onClick={backToBrowse}>
            <i className="fa fa-arrow-left" alt="back to browsing" />
            <span>back to browsing</span>
          </button>
        </div>
        <div className={headerWatchCss.rightNav}>{playingNext ? nextPlaying : nextButton}</div>
      </div>
    </header>
  );
}

export default Header;
