import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default (props) => (
  <Snackbar
    key={props.message.key ? props.message.key : undefined}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={props.open}
    onClose={props.onClose}
    autoHideDuration={6000}
  >
    <Alert severity={props.message.type || 'info'} onClose={props.onClose} variant="filled">
      {props.message ? props.message.text : undefined}
    </Alert>
  </Snackbar>
);

const types = {
  error: 'error',
  warn: 'warning',
  info: 'info',
  success: 'success',
};
export { types };
