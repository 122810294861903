import React from 'react';
import FullPage from '../common/FullPage';

const Info = (props) => {
  document.title = 'Couchclimbs - Feed';
  return (
    <FullPage {...props}>
      <div></div>
    </FullPage>
  );
};

/**
 * 
 * <a href="#feed" title="Go to feed">
          feed
        </a>
        {' | '}
        <a href="#roadmap" title="Go to roadmap">
          roadmap
        </a>
        {' | '}
        <a href="/about" title="Go to about">
          about
        </a>
        <hr id="feed"></hr>
        <h3>Feed</h3>
        <h5>24may2020</h5>
        Added:
        <ul>
          <li>https://www.youtube.com/watch?v=NQwjr1d5o30</li>
          <li>https://www.youtube.com/watch?v=3B2c1SXbNGY</li>
          <li>https://www.youtube.com/watch?v=xZYAvHcQ-eo</li>
          <li>https://www.youtube.com/watch?v=VY1i0buPS8I</li>
          <li>https://www.youtube.com/watch?v=WLv3eiMoKzE</li>
        </ul>
        <h5>17may2020</h5>
        Added:
        <ul>
          <li>https://www.youtube.com/watch?v=Hbo3nkms0a4</li>
          <li>https://www.youtube.com/watch?v=75Zm5hC5Q8o</li>
          <li>https://www.youtube.com/watch?v=Fco-hX0PW_c</li>
          <li>https://www.youtube.com/watch?v=yn7ocg9o8VI</li>
          <li>https://www.youtube.com/watch?v=wFJV2Rt4Ya8</li>
          <li>https://www.youtube.com/watch?v=nYvZdNyyWG8</li>
          <li>https://www.youtube.com/watch?v=cVSC9vIb-PU</li>
          <li>https://www.youtube.com/watch?v=gDeolxP-W6M</li>
        </ul>
        <h5>11may2020</h5>
        Added:
        <ul>
          <li>https://www.youtube.com/watch?v=YPEHKwF1LAU</li>
          <li>https://www.youtube.com/watch?v=mkfctVudZXk</li>
          <li>https://www.youtube.com/watch?v=ULqjiUXEiUs</li>
          <li>https://www.youtube.com/watch?v=SM70wIKPQps</li>
          <li>https://www.youtube.com/watch?v=O_3WlxO5i2Y</li>
          <li>https://www.youtube.com/watch?v=u9tGGRvue0E</li>
          <li>https://www.youtube.com/watch?v=2quugpJRAms</li>
          <li>https://www.youtube.com/watch?v=KPS_6lCUERE</li>
          <li>https://www.youtube.com/watch?v=9zVOQn35Lv4</li>
          <li>https://www.youtube.com/watch?v=Zjcg2gthjJc</li>
          <li>https://www.youtube.com/watch?v=KWCD-VdHy3A</li>
          <li>https://www.youtube.com/watch?v=QtTblK-4GJ4</li>
          <li>Climbers: Romain Desgranges, Charlotte Durif, Robbie Phillips</li>
        </ul>
        <br></br>
        <hr id="roadmap"></hr>
        <h3>Roadmap</h3>
        <br></br>
        must for 1.0
        <ul>
          <li>done!</li>
        </ul>
        <hr></hr>
        cool
        <ul>
          <li>mark film as already watched and hide from searches</li>
          <li>add filter to main screen: hide watched, duration</li>
          <li>add vimeo support</li>
          <li>locations parsing to be followed by space. eg: arco is part of narcosis.</li>
          <li>grade search, 6a-5.whatever are the same thing, so is 6A-V0</li>
          <li>if no search, show results from youtube? - or just redirect</li>
          <li>email signup if needed</li>
          <li>responsive left menu Material-UI responsive Menu comp like netflix</li>
          <li>login link in alerts component</li>
        </ul>
        <hr></hr>
        nice to have
        <ul>
          <li>make search instant on click, but delayed on type</li>
          <li>search by duration</li>
          <li>add more info on detail page</li>
          <li>add description icon on detail with tooltip containing actual description</li>
          <li>page to add videos manually (? possible)</li>
          <li>make searching by grades cool, as in 8c/15whatever interchangeable</li>
          <li>make searching by location cool as in Climb(?) -> Crag -> Country</li>
          <li>search by language?</li>
          <li>browse all movies - with inline filters like duration, release etc asc desc</li>
          <li>select random, set duration?</li>
        </ul>
 */

export default Info;
