import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseConfig from '../config/firebaseConfig';
import { firestoreInit } from './firestore';

const init = async () => {
  if (!firebase.apps.length) {
    await firebase.initializeApp(firebaseConfig);
    firebase.analytics().logEvent('notification_received');
    await firestoreInit();
  }
};

export default init;
