import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import FullPage from './FullPage';

const LoadingPage = (props) => (
  <FullPage center="true" {...props}>
    <div className="spinner">
      <CircularProgress color="white" size="40px" />
    </div>
    <Typography>{props.text}</Typography>
  </FullPage>
);

export default LoadingPage;
