import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

let db = null;
const firestoreInit = () => {
  try {
    db = firebase.firestore();
    db.enablePersistence({ synchronizeTabs: true });
    // if (window.location.hostname === 'localhost') {
    //   db.settings({
    //     host: 'localhost:8080',
    //     ssl: false,
    //   });
    // }

    return db;
  } catch (e) {
    if (e.code === 'unimplemented') {
      console.log('unimplemented');
    }
  }
};

const getAllDocuments = async (collection) => {
  const docs = [];
  try {
    const snapshot = await db.collection(collection).get();
    snapshot.forEach((doc) => {
      const data = doc.data();
      docs.push(data);
    });
  } catch (e) {
    console.log(e);
  }
  return docs;
};

const getCollection = (collection) => {
  return db.collection(collection);
};

const getSubCollection = (collection) => {
  return db.collectionGroup(collection);
};

const getDoc = (collection, doc) => {
  return db.collection(collection).doc(doc);
};

const getData = async (query) => {
  const docs = [];
  const snapshot = await query.get();
  snapshot.forEach((doc) => {
    const data = doc.data();
    docs.push(data);
  });
  return docs;
};

const set = async (collection, doc, data, merge = false) => {
  return db.collection(collection).doc(doc).set(data, { merge });
};

const update = async (collection, doc, data) => {
  return db.collection(collection).doc(doc).update(data);
};

const add = async (collection, data) => {
  return db.collection(collection).add(data);
};

const get = async (collection, doc) => {
  const snapshot = await db.collection(collection).doc(doc).get();
  return snapshot.data();
};

const getParentsData = async (query) => {
  const data = [];
  const { docs } = await query.get();
  for (let i = 0; i < docs.length; i++) {
    const parent = await docs[i].ref.parent.parent.get();
    data.push(parent.data());
  }
  return data;
};

export {
  firestoreInit,
  getAllDocuments,
  getData,
  getCollection,
  getSubCollection,
  set,
  get,
  add,
  update,
  getDoc,
  getParentsData,
};
