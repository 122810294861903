import React, { useState, useEffect } from 'react';
import HtmlTooltip from '../common/HtmlTooltip';
import { addToMyList, removeFromMyList, getMyList } from '../../data/user';
import analytics from '../../data/analytics';
import addCss from './addToMyList.module.css';
import { sendAlert } from '../../utils';

const AddToMyList = (props) => {
  const isAnon = props.user ? props.user.isAnonymous : true;
  const [loading, setLoading] = useState(false);
  const addClass = isAnon || loading ? [addCss.add, addCss.disabled].join(' ') : addCss.add;
  const [addState, setAdd] = useState(isAnon ? true : 'loading');
  const tooltip = isAnon ? 'Login to Manage My List' : addState === true ? 'Add to My List' : 'Remove from My List';

  useEffect(() => {
    const getData = async () => {
      const data = (await getMyList(props.film.id)) || { myList: false };
      setAdd(!data.myList);
    };
    if (!isAnon) getData();
  }, [isAnon, props.film.id]);

  const addToList = async () => {
    if (loading) return;
    if (isAnon) {
      analytics.logEvent(analytics.events.addToBlockedList);
      sendAlert('1', 'This feature requires Login.');
      return;
    }
    try {
      setLoading(true);
      await addToMyList(props.film);
      analytics.logEvent(analytics.events.addToList);
      setAdd(false);
    } catch (e) {
      console.log(e);
      analytics.logEvent(analytics.events.addToListFails, { e: e.message });
      sendAlert('2', e.message, 'warning');
    }
    setLoading(false);
  };

  const removeFromList = async () => {
    if (loading) return;
    if (isAnon) return;
    try {
      setLoading(true);
      await removeFromMyList(props.film.id);
      setAdd(true);
    } catch (e) {
      console.log(e);
      analytics.logEvent(analytics.events.removeFromListFails, { e: e.message });
      sendAlert('3', e.message, 'warning');
    }
    setLoading(false);
  };

  const icon =
    addState === 'loading' ? (
      <i className="fa fa-circle" alt="not loaded"></i>
    ) : addState ? (
      <i className="fa fa-plus-circle" alt="add to my list"></i>
    ) : (
      <i className="fa fa-minus-circle" alt="remove from my list"></i>
    );

  return (
    <HtmlTooltip title={tooltip} placement="top">
      <div className={addClass} onClick={addState ? addToList : removeFromList}>
        {icon}
      </div>
    </HtmlTooltip>
  );
};

export default AddToMyList;
