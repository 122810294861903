import React from 'react';
import { Link, useMatch } from '@reach/router';
import auth from '../../data/auth';
import SearchBar from '../search/SearchBar';
import headerCss from './header.module.css';
import HeaderWatch from './HeaderWatch';
import Menu from './Menu';
import { sendAlert, playRandomFilm } from '../../utils';

function Header(props) {
  const user = auth.getUser();
  const isDetail = useMatch('/watch/:watchId');
  const myList = useMatch('/search/mylist');
  const latest = useMatch('/search/latest');
  const latestCss = latest ? [headerCss.active, headerCss.latest].join(' ') : headerCss.latest;
  const isAnon = user ? user.isAnonymous : true;
  const disabledList = (
    <div onClick={() => sendAlert('55', 'This feature requires Login.')}>
      <Link to="#" title="My List">
        My List
      </Link>
    </div>
  );
  const enabledList = (
    <div className={myList ? headerCss.active : ''}>
      <Link to="/search/mylist" title="My List">
        My List
      </Link>
    </div>
  );

  const random = async () => {
    playRandomFilm('mainmenu');
  };
  const list = isAnon ? disabledList : enabledList;
  const titleElement = (
    <div className={headerCss.header}>
      <div className={headerCss.navigation}>
        <div className={headerCss.leftNav}>
          <div className={headerCss.logo}>
            <Link to="/" title="Home">
              <img src="/logo.png" alt="Home" />
              <span>couchclimbs</span>
            </Link>
          </div>
          {list}
          <div className={latestCss}>
            <Link to="/search/latest" title="Latest">
              Latest
            </Link>
          </div>
          <div className={headerCss.random}>
            <Link to="?" onClick={random} title="Random">
              <i className="fa fa-random" />
              <span>Shuffle</span>
            </Link>
          </div>
        </div>
        <div className={headerCss.rightNav}>
          <SearchBar />
          <Menu isAnon={isAnon} />
        </div>
      </div>
    </div>
  );

  const detailElement = <HeaderWatch />;
  const content = isDetail ? detailElement : titleElement;
  return <header className={headerCss.mheader}>{content}</header>;
}

export default Header;
