import React from 'react';
import { Link } from '@reach/router';

const Copyright = (props) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ height: props.height || '800px' }}></div>
      <Link to="/privacy" title="Privacy">
        Privacy
      </Link>
      <br />
      <Link to="/terms" title="Terms">
        Terms
      </Link>
      <br />
      <Link to="/about" title="About">
        About
      </Link>
      <br />
      <div>© 2020 Grigor.</div>
    </div>
  );
};

export default Copyright;
