import firebase from 'firebase/app';
import 'firebase/analytics';

const events = {
  play: 'onPlay',
  search: 'onSearch',
  addToList: 'addToMyList',
  addToBlockedList: 'addToBlockedList',
  addToListFails: 'addToListFails',
  removeFromListFails: 'removeFromListFails',
  loadCategory: 'loadCategory',
  login: 'login',
  loginFail: 'loginFail',
  logout: 'logout',
  logoutFail: 'logoutFail',
  backToBrowsing: 'backToBrowsing',
  random: 'random',
  calcRandom: 'calcRandom',
  playNext: 'playNext',
  cancelPlayNext: 'cancelPlayNext',
  playNextCountedDown: 'playNextCountedDown',
};

const logEvent = (eventName, data = {}) => {
  firebase.analytics().logEvent(eventName, data);
};
export default { logEvent, events };
