import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';
import watchCss from './watch.module.css';
import { getFilmById, getStaticFilmById } from '../../data/films';
import { getTime } from '../../utils';
import Player from '../../player/youtube';

const Watch = (props) => {
  const [film, setFilm] = useState(null);

  const staticFilm = getStaticFilmById(props.movieId);
  if (staticFilm) document.title = staticFilm.title;
  else {
    const title = queryString.parse(props.location ? props.location.search : {}).title;
    document.title = title ? title : 'CouchClimbs - A Library of Rock Climbing Movies';
  }

  useEffect(() => {
    const getFilm = async (id) => {
      const film = await getFilmById(id);
      document.title = film.title;
      setFilm(film);
    };
    if (staticFilm) setFilm(staticFilm);
    else getFilm(props.movieId);
  }, [props.movieId, staticFilm]);

  const content = film ? (
    <div style={{ marginTop: '20px' }}>
      <h2>{film.title}</h2>
      <Typography variant="h6" paragraph={true}>
        {film.channelTitle} - <i className="fa fa-clock-o" /> {getTime(film.data.contentDetails.duration)}
      </Typography>
      <Typography variant="body1" paragraph={true}>
        {film.description}
      </Typography>
      <Typography variant="body2"></Typography>
      <Typography variant="caption">Meta: {film.meta ? film.meta.join(' - ') : ''}</Typography>
    </div>
  ) : (
    ''
  );

  const onEnd = () => {
    document.body.dispatchEvent(
      new CustomEvent('headerWatch', {
        detail: { action: 'next' },
      })
    );
  };

  return (
    <div>
      <div className={watchCss.cover}>
        <div className={watchCss.background}></div>
        {/* <div style={{ height: '180px', width: '180px' }}></div> */}
        <div className={watchCss.player}>
          <Player videoId={props.movieId} onEnd={onEnd} />
        </div>
      </div>
      {content}
    </div>
  );
};

export default Watch;
