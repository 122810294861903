import React from 'react';
import auth from '../data/auth';
import analytics from '../data/analytics';
import { sendAlert } from '../utils';

class Logout extends React.Component {
  componentDidMount = async () => {
    try {
      document.title = "Logout"
      analytics.logEvent(analytics.events.logout);
      await auth.signOut();
      setTimeout(() => {
        sendAlert('66', 'You have logged out.');
      }, 2000);
      window.location.href = '/';
    } catch (e) {
      analytics.logEvent(analytics.events.logoutFail, { e: e.message });
      console.log(e);
    }
  };

  render() {
    return <div></div>;
  }
}

export default Logout;
