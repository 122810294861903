import React, { useState, useEffect } from 'react';
import debounce from 'debounce';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import { getAllCategoriesElements } from '../../data/films';
import analytics from '../../data/analytics';
import Autocomplete from '@material-ui/lab/Autocomplete';
import searchClasses from './search.module.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '200px',
    '& .MuiFormControl-root': {
      marginTop: '8px',
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '3px',
    },
    '& .MuiInputBase-root': {
      fontSize: '14px',
      marginLeft: '-24px',
      paddingLeft: '28px',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
}));

function SearchBar() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [text, setText] = useState('');

  useEffect(() => {
    const loadCategories = async () => {
      const categories = await getAllCategoriesElements();
      setCategories(categories);
    };
    loadCategories();
  }, []);

  const onSearch = (e, searchText) => {
    if (text === searchText) return;
    analytics.logEvent(analytics.events.search, { searchText });
    setText(searchText);
    if (!searchText) navigate(`/`);
    else navigate(`/search/${searchText}`);
  };
  const debouncedSearch = debounce(onSearch, 1000);
  return (
    <div className={searchClasses.search}>
      <i className="fa fa-search" alt="search" />
      <Autocomplete
        id="search"
        limitTags="10"
        classes={{ root: classes.root }}
        freeSolo
        options={categories.map((option) => option)}
        // onChage={onSearch}
        onInputChange={debouncedSearch}
        renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" />}
      />
    </div>
  );
}

export default SearchBar;
