import React from 'react';
import { navigate } from '@reach/router';
import { Button } from '@material-ui/core';
import auth from '../data/auth';
import LoadingPage from './common/LoadingPage';
import FullPage from './common/FullPage';
import analytics from '../data/analytics';
import { sendAlert } from '../utils';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: (localStorage.getItem('login') || false) === 'true' };
    setTimeout(() => {
      if ((localStorage.getItem('login') || false) === 'true') {
        localStorage.setItem('login', false);
        sendAlert('1', 'something went wrong, might need a manual refresh', 'error');
        navigate('/login');
      }
    }, 3000);
  }

  componentDidMount = async () => {
    document.title = 'Couchclimbs - Login';
    const user = await auth.getAuth();
    if (user && !user.isAnonymous) {
      localStorage.setItem('login', false);
      navigate('/');
    } else auth.getRedirectAuth(this.onSuccessLogin, this.onFailLogin);
  };

  googleLogin = () => {
    localStorage.setItem('login', true);
    analytics.logEvent(analytics.events.login, { provider: auth.providers.google });
    auth.login(auth.providers.google);
  };

  facebookLogin = () => {
    localStorage.setItem('login', true);
    analytics.logEvent(analytics.events.login, { provider: auth.providers.facebook });
    auth.login(auth.providers.facebook);
  };

  onSuccessLogin = (credentials) => {
    localStorage.setItem('login', false);
    if (credentials) navigate('/');
  };

  onFailLogin = (error) => {
    localStorage.setItem('login', false);
    console.log(error);
    analytics.logEvent(analytics.events.loginFail, { e: error.message });
    sendAlert('1', error.errorMessage, 'error');
    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingPage text="Authenticating" {...this.props} />;
    return (
      <FullPage center="true" {...this.props}>
        <h3>Login</h3>
        <Button variant="outlined" onClick={this.googleLogin}>
          Sign-In with Google
        </Button>
        <br />
        <br />
        <Button variant="outlined" onClick={this.facebookLogin}>
          Sign-In with Facebook
        </Button>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h5>
          Log-in is required for features like My List, marking movies as Watched and anything else that requires user
          identification.
        </h5>
      </FullPage>
    );
  }
}

export default Login;
