import React from 'react';
import auth from '../../data/auth';
import { Link } from '@reach/router';
import HtmlTooltip from '../common/HtmlTooltip';
import AddToMyList from './AddToMyList';
import { getTime, isMobile } from '../../utils';
import detail from './list-detail.module.css';

const ListDetail = (props) => {
  const user = auth.getUser();
  const link = `/watch/${props.film.id}?title=${props.film.title.replace('|', '')}`;
  const addToList = props.dumb ? <div /> : <AddToMyList film={props.film} user={user} />;
  const imgSrc = isMobile()
    ? `${props.film.data.snippet.thumbnails.default.url}`
    : `${props.film.data.snippet.thumbnails.medium.url}`;

  const onDetail = () => {
    localStorage.setItem('backto', window.location.pathname);
  };
  return (
    <div style={props.dumb ? { visibility: 'hidden' } : {}} className={props.dumb ? '' : detail.animation}>
      <div className={props.dumb ? '' : detail.detail}>
        <div className={props.dumb ? '' : detail.content}>
          <img className={['lazyload', detail.background].join(' ')} data-src={imgSrc} alt="thumbnail" />
          <div className={detail.hitbox}>
            <HtmlTooltip title={props.film.title} enterDelay={2000} placement="top">
              <div className={detail.title}>{props.film.title}</div>
            </HtmlTooltip>
            <div className={detail.play}>
              <Link to={link} title={props.film.title} onClick={onDetail}>
                <i className="fa fa-play-circle-o" alt="play"></i>
              </Link>
            </div>
          </div>

          <div className={detail.overview}>
            <div className={detail.overviewContent}>
              <div className={detail.channel}>
                <i className="fa fa-user-circle-o" alt="channel name"></i>
                {`  ${props.film.data.snippet.channelTitle}`}
              </div>
              <div className={detail.description}>
                <div>
                  {`  ${props.film.data.statistics.viewCount}`}
                  <i className="fa fa-eye" alt="views"></i>
                </div>
                <div>
                  {`  ${props.film.data.snippet.defaultAudioLanguage || 'en'}`}
                  <i className="fa fa-globe" alt="language"></i>
                </div>
                <div>
                  {`  ${getTime(props.film.data.contentDetails.duration)}`}
                  <i className="fa fa-clock-o" alt="duration"></i>
                </div>
              </div>
              {/*<div className={detail.description}>{props.film.data.snippet.description.substring(0, 100)}[...]</div>*/}
              <div className="bob-overview-resume-title-wrapper"></div>
            </div>
          </div>
          <div className={detail.actions}>
            <div className={detail.actionsContent}>
              {addToList}
              <HtmlTooltip title={props.film.description}>
                <div className={detail.info}>
                  <i className="fa fa-newspaper-o" alt="description on hover"></i>
                </div>
              </HtmlTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListDetail;
