import React, { useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import { search, getMostRecent } from '../../data/films';
import { getByMyList } from '../../data/user';
import { capitalize } from '../../utils';
import searchClasses from './search.module.css';
import SearchList from './SearchList';

function Search() {
  const [searching, setSearching] = useState(false);
  const [films, setFilms] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const params = useParams();

  document.title = capitalize(params.query || '');

  const searchMoreFilms = async () => {
    let resFilms = [];
    if (params.query.toLowerCase() === 'mylist') resFilms = await getByMyList(20, films[films.length - 1].dateAdded);
    else if (params.query.toLowerCase() === 'latest')
      resFilms = await getMostRecent(20, films[films.length - 1].dateAdded);
    else resFilms = await search(params.query.toLowerCase(), films[films.length - 1].views);
    if (resFilms.length === 0) setHasMore(false);
    setFilms(films.concat(resFilms));
  };

  useEffect(() => {
    const searchFilms = async () => {
      let films = [];
      setSearching(true);
      if (params.query.toLowerCase() === 'mylist') films = await getByMyList(20);
      else if (params.query.toLowerCase() === 'latest') films = await getMostRecent(20);
      else films = await search(params.query.toLowerCase());
      if (films.length === 0) setHasMore(false);
      setFilms(films);
      setSearching(false);
    };
    searchFilms();
  }, [params.query]);

  const searchNext = () => {
    if (page > 5) return; // limit to 100 searches
    searchMoreFilms();
    setPage(page + 1);
  };

  return (
    <div className={searchClasses.page}>
      <h2>{document.title}</h2>
      <SearchList films={films} searching={searching} searchNext={searchNext} hasMore={hasMore} />
    </div>
  );
}

export default Search;
