import React from 'react';
import FullPage from '../common/FullPage';
import { Typography } from '@material-ui/core';

const About = (props) => {
  document.title = 'Couchclimbs - About';
  return (
    <FullPage {...props}>
      <div>
        <h2>About</h2>
        <Typography>
          This website is the product of 'Rona2020 boredrom. And hatred for youtubes recommendation and play UX.
          <br />
          For more info{' '}
          <a href="mailto:info.rockmov@gmail.com" title="contact me">
            contact me
          </a>{' '}
          or leave a feedback via top-right corner menu. Thanks!
        </Typography>
      </div>
    </FullPage>
  );
};

export default About;
