import React from 'react';
import Swiper from 'react-id-swiper';
import debounce from 'debounce';
import { CircularProgress } from '@material-ui/core';
import ListDetail from './ListDetail';
import './swiper.css';
import listCss from './list.module.css';

const getSwiperParams = () => ({
  observer: true,
  slidesPerView: 'auto',
  shouldSwiperUpdate: true,
  spaceBetween: 5,
  slidesPerGroup: 2,
  // pagination: {
  //   el: '.swiper-pagination',
  //   type: 'fraction',
  // },
  navigation: {
    nextEl: '.swiper-button-next.swiper-custom-next',
    prevEl: '.swiper-button-prev.swiper-custom-prev',
  },
  slideClass: 'swiper-custom-slide',
  containerClass: 'swiper-container swiper-custom-container',
});

const isPreviousOfSelected = (films, index, filmId) => {
  return index >= 0 && films[index].id === filmId;
};

const isNextOfSelected = (films, index, filmId) => {
  return index < films.length && films[index].id === filmId;
};

class ListFilms extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedMouseOver = debounce(this.onMouseOver, 400);
    this.resetDebounce = debounce(() => {
      this.cancelDebounce = null;
    }, 401);
    this.state = { loading: true, films: [] };
    if (props.defaultCat) {
      this.state = { loading: false, films: props.getFilms() };
    }
  }

  componentDidMount = async () => {
    if (!this.props.defaultCat) {
      const films = await this.props.getFilms();
      this.setState({ films, loading: false });
    }
  };

  componentDidUpdate = () => {
    if (this.state.loading && this.state.films.length) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  };

  onMouseOver = (film) => {
    if (film.id !== this.cancelDebounce) this.setState({ filmId: film.id });
    this.cancelDebounce = null;
  };

  onMouseOut = (film) => {
    this.cancelDebounce = film.id;
    this.setState({ filmId: null });
    this.resetDebounce();
  };

  render() {
    if (this.state.loading)
      return (
        <div className={listCss.content}>
          <h2>{this.props.title}</h2>
          <div className={listCss.loading}>
            <CircularProgress color="secondary" />
          </div>
        </div>
      );

    // const ad = (
    //   <ins
    //     className="adsbygoogle"
    //     style={{ display: 'block' }}
    //     data-ad-client="ca-pub-7086512592553390"
    //     data-ad-slot="7489680158"
    //     data-ad-format="auto"
    //     data-full-width-responsive="true"
    //     key="googlead"
    //   ></ins>
    // );

    const swiperParams = getSwiperParams();
    const films = this.state.films.map((film, index) => {
      if (film.data) {
        const prevMargin = isPreviousOfSelected(this.state.films, index - 1, this.state.filmId)
          ? 'swiper-previous-margin'
          : '';
        const nextMargin = isNextOfSelected(this.state.films, index + 1, this.state.filmId) ? 'swiper-next-margin' : '';

        // if (index === 3) {
        //   return (
        //     <div className={[prevMargin, nextMargin].join(' ')} key={film.id}>
        //       {ad}
        //     </div>
        //   );
        // }
        const style = {
          backgroundSize: 'cover',
          backgroundPositionY: '50%',
          backgroundImage: `url(${film.data.snippet.thumbnails.high.url}`,
        };
        let content =
          this.state.filmId === film.id ? (
            <ListDetail film={film} />
          ) : (
            <div
              className="swiper-content"
              onClick={(e) => this.onMouseOver(film)}
              onMouseOver={(e) => this.debouncedMouseOver(film)}
            >
              {film.title}
              <ListDetail film={film} dumb={true} />
            </div>
          );
        // if (index === 1) content = <ListDetail vis={true} film={film}/>;
        return (
          <div
            className={[prevMargin, nextMargin].join(' ')}
            key={film.id}
            style={style}
            onMouseLeave={() => this.onMouseOut(film)}
          >
            {content}
          </div>
        );
      }
      return null;
    });
    return (
      <div className={listCss.content}>
        <h2>{this.props.title}</h2>
        <Swiper {...swiperParams}>{films}</Swiper>
      </div>
    );
  }
}

export default ListFilms;
