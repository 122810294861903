import React, { useState } from 'react';
import { navigate } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import menuCss from './menu.module.css';
import FooterButton, { type } from './MenuButton';
import Feedback from '../common/Feedback';
import { sendFeedback } from '../../data/user';
import { sendAlert } from '../../utils';

const MenuElement = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFeedback, setOpenFeedback] = useState(false);
  const open = Boolean(anchorEl);
  const authType = !props.isAnon ? type.logout : type.login;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFeedbackClose = () => {
    setOpenFeedback(false);
  };

  const onFeedbackOpen = () => {
    setAnchorEl(null);
    setOpenFeedback(true);
  };

  const onFeedbackSubmit = (rating, text) => {
    sendFeedback(rating, text);
    sendAlert('feedback', 'Thank you for your feedback!', 'success');
    setOpenFeedback(false);
  };

  return (
    <div>
      <IconButton
        className={menuCss.menuButton}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <i className="fa fa-bars" />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        disableScrollLock={true}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onFeedbackOpen}>
          <FooterButton type={type.feedback} />
        </MenuItem>
        <MenuItem onClick={() => navigate(type.info.path)}>
          <FooterButton type={type.info} />
        </MenuItem>
        <MenuItem onClick={() => navigate(authType.path)}>
          <FooterButton type={authType} />
        </MenuItem>
      </Menu>
      <Feedback open={openFeedback} onClose={onFeedbackClose} onSubmit={onFeedbackSubmit} />
    </div>
  );
};

export default MenuElement;
