import React from 'react';
import FullPage from './FullPage';

export default (props) => {
  document.title = '404';
  return (
    <FullPage center="true" copyright={false} {...props}>
      <h2>404 nothing here</h2>
    </FullPage>
  );
};
