import firebase from 'firebase/app';
import 'firebase/auth';

let currentUser = null;
const providers = {
  google: 'Google',
  facebook: 'Facebook',
};

const login = (providerName) => {
  let provider = null;
  if (providerName === providers.google) provider = new firebase.auth.GoogleAuthProvider();
  else if (providerName === providers.facebook) provider = new firebase.auth.FacebookAuthProvider();

  firebase.auth().signInWithRedirect(provider);
};

const getRedirectAuth = (success, fail) => {
  firebase
    .auth()
    .getRedirectResult()
    .then(function (result) {
      if (result.credential) {
        success(result.credential);
      }
    })
    .catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
      fail({ errorMessage, errorCode, email, credential });
    });
};

const getAuth = async () => {
  try {
    return await new Promise((resolve, reject) =>
      firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            currentUser = user;
            resolve(user);
          } else {
            firebase
              .auth()
              .signInAnonymously()
              .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
              });
          }
        },
        (error) => reject(error)
      )
    );
  } catch (error) {
    return null;
  }
};

const getUser = () => {
  return firebase.auth().currentUser || currentUser;
};

const signOut = async () => {
  await firebase.auth().signOut();
  currentUser = null;
};

export default { login, getAuth, getUser, signOut, getRedirectAuth, providers };
