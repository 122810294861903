import React from 'react';
import { hydrate, render } from 'react-dom';
import 'lazysizes';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import Router from './Router';
import LoadingPage from './layout/common/LoadingPage';
import * as serviceWorker from './serviceWorker';
import init from './data/firebase';
import auth from './data/auth';
import { getTopCategory } from './data/films';

const renderMe = async () => {
  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <LoadingPage location={{ pathname: '/' }} />
      </React.StrictMode>,
      rootElement
    );
  } else {
    render(
      <React.StrictMode>
        <LoadingPage location={{ pathname: '/' }} />
      </React.StrictMode>,
      rootElement
    );
  }

  // ReactDOM.render(
  //   <React.StrictMode>
  //     <LoadingPage location={{ pathname: '/' }} />
  //   </React.StrictMode>,
  //   document.getElementById('root')
  // );

  await init();
  try {
    await auth.getAuth();
    await getTopCategory();
  } catch (e) {
    console.log(e);
  }

  if (rootElement.hasChildNodes()) {
    hydrate(
      <React.StrictMode>
        <Router />
      </React.StrictMode>,
      rootElement
    );
  } else {
    render(
      <React.StrictMode>
        <Router />
      </React.StrictMode>,
      rootElement
    );
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
};

renderMe();
