import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import debounce from 'debounce';
import ListDetail from '../list/ListDetail';
import search from './search.module.css';

class SearchList extends React.Component {
  constructor() {
    super();
    this.state = { filmId: null };
    this.debouncedMouseOver = debounce(this.onMouseOver, 400);
    this.resetDebounce = debounce(() => {
      this.cancelDebounce = null;
    }, 401);
  }

  onMouseOver = (film) => {
    if (film.id !== this.cancelDebounce) this.setState({ filmId: film.id });
    this.cancelDebounce = null;
  };

  onMouseOut = (film) => {
    this.cancelDebounce = film.id;
    this.setState({ filmId: null });
    this.resetDebounce();
  };

  render() {
    if (this.props.searching)
      return (
        <div className="centered-content">
          <CircularProgress color="secondary" />
        </div>
      );
    if (this.props.films.length === 0)
      return (
        <div className="centered-content">
          <h2>No Results!</h2>
          <br />
          <p>Try searching by a single keyword or by the suggested keywords.</p>
          <p>Click here to suggest us this keyword.</p>
        </div>
      );
    const films = this.props.films.map((film) => {
      const style = {
        backgroundSize: 'cover',
        backgroundPositionY: '50%',
        backgroundImage: `url(${film.data.snippet.thumbnails.high.url}`,
      };

      let content =
        this.state.filmId === film.id ? (
          <ListDetail vis={this.state.filmId === film.id} film={film} />
        ) : (
          <div onClick={(e) => this.onMouseOver(film)} onMouseOver={(e) => this.debouncedMouseOver(film)}>
            {film.title}
          </div>
        );
      return (
        <div className={search.item} key={film.id} style={style} onMouseLeave={() => this.onMouseOut(film)}>
          {content}
        </div>
      );
    });
    return (
      <div>
        <InfiniteScroll
          className={search.results}
          dataLength={films.length}
          next={this.props.searchNext}
          hasMore={this.props.hasMore}
          loader={<div style={{ height: '200px' }}></div>}
          style={{ overflow: 'inherit' }}
          scrollThreshold="600px"
        >
          {films}
        </InfiniteScroll>
      </div>
    );
  }
}

export default SearchList;
